<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h1>{{ $t("appbar.tradingBot") }}</h1>
      </v-col>
    </v-row>
    <!-- -->
    <v-row dense v-show="mainLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>

    <v-row dense v-show="!mainLoading">
      <v-col cols="12">
        <v-card
          dark
          v-for="item in page_data"
          :key="item.id"
          class="my-2"
          :ripple="false"
          v-on:click="redictToDetail(item.id)"
        >
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="item.image"></v-img>
            </v-avatar>

            <div>
              <v-card-text>
                <p class="text-h5 text--primary">
                  {{ $changeLang(item.title) }}
                </p>
                <p>{{ $changeLang(item.subtitle) }}</p>
                <div class="text--primary">
                  Monthly Return
                  <span class="green--text text--darken-1 font-weight-bold">
                    {{ item.monthly_return }} %</span
                  >
                  Max Drawdown
                  <span class="red--text text--darken-1 font-weight-bold">
                    {{ item.maximum_drawdown }} %</span
                  >
                </div>
                <div class="mt-1">
                  <span v-for="tag in item.tags" :key="tag">
                    <v-chip
                      color="#294664"
                      class="mr-1"
                      :ripple="false"
                      label
                      >{{ tag }}</v-chip
                    >
                  </span>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRobotList } from "@/services/api/robot";
import MainLoading from "../subcomponents/mainLoading.vue";


export default {
  components: { MainLoading },
  name: "RobotList",
  data: () => ({
    page_data: [],
    mainLoading: true,
  }),
  methods: {
    async getData() {
      let _page_data = await getRobotList();
      for (let i = 0; i < _page_data.length; i++) {
        _page_data[i]["monthly_return"] =
          _page_data[i]["monthly_return"].toFixed(2);
        _page_data[i]["maximum_drawdown"] =
          _page_data[i]["maximum_drawdown"].toFixed(2);
      }
      this.page_data = _page_data;
      this.mainLoading = false;
    },
    redictToDetail(robot_id) {
      this.$router.push({
        path: "/robot",
        query: { code: robot_id },
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
/* .v-chip__content {
  cursor: pointer !important;
} */
</style>
